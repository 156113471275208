import React from "react";
import AuthContext from "../../../../AuthContext";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";

const { chatService, companyService } = mtzApis;

function RegularRooms({ room, onChange, newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState();
    let [rooms, setRooms] = React.useState([]);
    let [newRoom, setNewRoom] = React.useState({});
    let [users, setUsers] = React.useState([]);
    let [userK, setUserK] = React.useState('');
    const me = React.useContext(AuthContext);
    const modalAddRoomDismissRef = React.useRef();

    React.useEffect(() => {
        let mounted = true;

        if (room)
            if (!active || active.id !== room.id)
                if (mounted)
                    setActive(room);

        mtzApis.startSpinningIcon();
        (async () => {
            rooms = await chatService.getRooms(new URLSearchParams(`meta.type=REGULAR`));
            rooms = await loadRoomInfo(rooms);
            if (mounted) {
                setRooms(rooms || []);
            }
        })();
        mtzApis.stopSpinningIcon();
        return () => mounted = false;
    }, [room]);

    // room event
    React.useEffect(() => {
        let mounted = true;
        (async () => {
            if (newRoomEvent && newRoomEvent.resource && newRoomEvent.resource.meta.type === 'REGULAR') {
                let newRoom = newRoomEvent.resource;
                newRoom = (await loadRoomInfo([newRoom]))[0];

                if (newRoomEvent.action === 'CREATED') {
                    setRooms(prevRooms => [newRoom, ...prevRooms]);
                }

                if (newRoomEvent.action === 'UPDATED') {
                    let found = rooms.find(r => r.id === newRoom.id);

                    if (found)
                        if (!newRoom.memberIds.includes(me.userId)) {
                            if (mounted) setRooms(prevRooms => prevRooms.filter(r => r.id !== newRoom.id))
                            if (active && newRoom.id === active.id)
                                if (mounted) onChange();
                        } else {
                            newRoom.members = await companyService.getUsers(new URLSearchParams(`userIds=${newRoom.memberIds}`));
                            if (mounted) setRooms(prevRooms => prevRooms.map(r => r.id === newRoom.id ? newRoom : r))
                            if (active && active.id === newRoom.id)
                                if (mounted) onChange(newRoom);
                        }

                    if (!found)
                        if (newRoom.memberIds.includes(me.userId))
                            if (mounted) setRooms(prevRooms => [newRoom, ...prevRooms])
                }

                if (newRoomEvent.action === 'DELETED') {
                    if (rooms)
                        if (mounted) setRooms(prevRooms => prevRooms.filter(r => r.id !== newRoom.id));
                    if (active && active.id === newRoom.id)
                        if (mounted) onChange();
                }
            }
        })();

        return () => mounted = false;
    }, [newRoomEvent]);

    // message event
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        (async () => {
            if (newMessageEvent.action === 'CREATED') {
                if (!active || msg.roomId !== active.id) {
                    let room = rooms.find(r => r.id === msg.roomId);
                    if (!room)
                        return;
                    if (room.unseenMsgs.find(m => m.id === msg.id))
                        return;
                    if (msg.viewerIds.includes(me.userId))
                        return;
                    room.unseenMsgs = [msg, ...(room.unseenMsgs || [])];
                    if (room.unseenMsgs.length > 11)
                        room.unseenMsgs = room.unseenMsgs.slice(0, 10 + 1);

                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? room : r));
                }
            }

            if (newMessageEvent.action === 'UPDATED') {
                if (msg.viewerIds.includes(me.userId)) {
                    let foundRoom = rooms.find(r => r.id === msg.roomId);
                    if (!foundRoom)
                        return;
                    if (room.unseenMsgs.find(m => m.id === msg.id))
                        return;
                    foundRoom.unseenMsgs = await chatService.getMessages(new URLSearchParams(`roomIds=${foundRoom.id}&notSeenBy=${me.userId}&limit=11`));
                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? foundRoom : r));
                    setActive(prevActive => prevActive.id === foundRoom.id ? foundRoom : prevActive);
                }
            }

            if (newMessageEvent.action === 'DELETED') {
                let room = rooms.find(r => r.id === msg.roomId);
                if (!room)
                    return;
                if (msg.viewerIds.includes(me.userId))
                    return;
                if (room.unseenMsgs && room.unseenMsgs.length > 0)
                    room.unseenMsgs = room.unseenMsgs.filter(m => m.id !== msg.id);
                setRooms(prevRooms => prevRooms.map(r => r.id === room.id ? room : r));
            }
        })();
    }, [newMessageEvent]);

    const loadRoomInfo = async roomList => {
        let reqs = roomList.map(r => chatService.getMessages(new URLSearchParams(`roomIds=${r.id}&notSeenBy=${me.userId}&limit=11`)));
        let data = await Promise.allSettled(reqs);
        let vals = data.map(res => res.value);

        let count = 0;
        roomList.forEach(r => {
            r.unseenMsgs = vals[count] || [];
            count++;
        });

        return roomList;
    };

    const createRoom = async e => {
        e.preventDefault();

        let createParams = { ...newRoom };
        if (newRoom.members)
            delete createParams.members;

        if (newRoom.logoFile)
            delete createParams.logoFile;

        mtzApis.startSpinningIcon();
        let created = await chatService.createRoom(createParams);
        if (created) {
            alert('Room created');

            // add logo
            if (newRoom.logoFile) {
                let formData = new FormData();
                formData.append('logoFile', newRoom.logoFile);
                created = await chatService.updateRoomLogoById(created.id, formData);
            }

            // add members
            if (newRoom.members) {
                let reqs = newRoom.members.map(m => chatService.createRoomInvite({ roomId: created.id, inviteeId: m.id }));
                let results = await Promise.allSettled(reqs);
                let invites = results.map(res => res.value);
                alert(`${invites.length} member invite(s) sent`);
            }

            setRooms([created, ...rooms]);
            onChange(created);
            setNewRoom({});
            setUserK('');
            setUsers([]);
            if (modalAddRoomDismissRef && modalAddRoomDismissRef.current)
                modalAddRoomDismissRef.current.click();
        }
        mtzApis.stopSpinningIcon();
    };

    const editRoom = async (roomId, changes) => {
        mtzApis.startSpinningIcon();
        if (changes.logoFile) {
            let formData = new FormData();
            formData.append('logoFile', changes.logoFile);
            await chatService.updateRoomLogoById(roomId, formData);
        } else {
            await chatService.updateRoomById(roomId, changes);
        }
        mtzApis.stopSpinningIcon();
    };

    const searchUser = async k => {
        if (!k) return;

        users = await companyService.getUsers(new URLSearchParams(`keyword=${k}`));
        setUsers(users);
    };

    return (
        <div className="d-flex flex-column mtz-gap-8">
            <div className="d-flex align-items-center">
                <h5 className="m-0 mtz-h5 flex-fill">Groups:</h5>
                <button data-toggle='modal' data-target='#modal-add-room' className="btn btn-outline-primary rounded-circle">
                    <span className="fa fa-plus"></span>
                </button>

                <div id='modal-add-room' className="modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header d-flex">
                                <h5 className="mtz-h5 flex-fill">Adding new room:</h5>

                                <button ref={modalAddRoomDismissRef} data-dismiss='modal' className="btn btn-sm rounded-circle">
                                    <span className="fa fa-close"></span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <form className="d-flex flex-column mtz-gap-16" onSubmit={createRoom}>
                                    <div className="form-group">
                                        <label>Group name:</label>
                                        <input value={newRoom.name || ''} className="form-control" onChange={e => setNewRoom({ ...newRoom, name: e.target.value })} />
                                    </div>

                                    <div className="form-group">
                                        <label>Group logo: (optional)</label>
                                        <input type='file'
                                            onChange={e => {
                                                setNewRoom({ ...newRoom, logoFile: e.target.files[0] });
                                                e.target.value = null;
                                            }}
                                            className="form-control" />
                                        {newRoom.logoFile && <span className="text-info"><b>Uploaded file:</b> newRoom.logoFile.name</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>Add members: (optional)</label>

                                        <div className="mb-2 d-flex mtz-gap-8 align-items-center">
                                            <b className="fa fa-users"></b>
                                            {!newRoom.members && 'No member added'}
                                            {
                                                newRoom.members && newRoom.members.map(m => (
                                                    <div key={m.id} className="align-items-center border px-2 mtz-rounded-16">
                                                        {m.firstname + " " + m.lastname} <button onClick={() => setNewRoom({ ...newRoom, members: newRoom.members.filter(item => item.id !== m.id) })}
                                                            className="btn btn-sm rounded-circle"><span className="text-danger fa fa-trash"></span></button>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <input placeholder="Search user" className="form-control" value={userK}
                                            onChange={e => {
                                                searchUser(e.target.value);
                                                setUserK(e.target.value);
                                            }} />
                                        {
                                            users && users.length > 0 &&
                                            <div className="list-group rounded-0 position-absolute vh-50 w-100 m-1 overflow-auto">
                                                {
                                                    users.map(user => (
                                                        <div key={user.id} className="list-group-item w-100 rounded-0">
                                                            <a href='#' onClick={() => {
                                                                setNewRoom({ ...newRoom, members: !newRoom.members ? [user] : [...newRoom.members, user] });
                                                                setUsers([]);
                                                                setUserK('');
                                                            }}>
                                                                {`${user.firstname} ${user.lastname}`}
                                                            </a>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>

                                    <button className="btn btn-primary">
                                        Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list-group">
                {
                    rooms === null &&
                    <div className="list-group-item">Loading...</div>
                }

                {
                    rooms && rooms.length === 0 &&
                    <div className="list-group-item text-center">
                        No room found
                    </div>
                }

                {
                    rooms && rooms.length !== 0 && rooms.map(room => (
                        <div key={room.id}
                            className={"mtz-rounded-16 border my-1 p-2" + (active && room.id === active.id ? ' bg-light' : ' ')}>
                            <div className="d-flex align-items-center mtz-gap-8">
                                <div className="flex-fill d-flex mtz-gap-8 align-items-center mtz-cursor-pointer"
                                    onClick={() => {
                                        if (!active || active.id !== room.id) {
                                            if (onChange)
                                                onChange(room);
                                        }
                                    }} >
                                    <img className="img-fluid rounded-circle" style={{ height: '50px', maxWidth: '50px' }}
                                        src={room.logoPath ? chatService.getBackendHost() + room.logoPath : '/assets/images/empty.png'} />
                                    <span className="">{room.name} {me.userId === room.creatorId ? <small className="text-danger">(admin)</small> : ''}</span>
                                </div>
                                <div className="dropdown">
                                    <button data-toggle='dropdown' className="btn" >
                                        <span className="fa fa-ellipsis-v"></span>
                                    </button>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-item">
                                            {
                                                me.userId === room.creatorId &&
                                                <button data-toggle='modal' data-target='#modal-edit-room' className="btn text-primary">
                                                    <span className="fa fa-edit"></span> Update
                                                </button>
                                            }

                                            {
                                                me.userId !== room.creatorId &&
                                                <button data-toggle='modal' data-target='#modal-view-room' className="btn">
                                                    <span className="fa fa-users"></span> Members
                                                </button>
                                            }
                                        </div>

                                        <div className="dropdown-item">
                                            <button onClick={async () => {
                                                const ok = await window.createMtzConfirm(`Are you sure?`);
                                                if (!ok) return;

                                                editRoom(room.id, { _leave: true });
                                            }} className="btn text-danger">
                                                <span className="fa fa-sign-out"></span> Leave
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {room && room.unseenMsgs && room.unseenMsgs.length > 0 ? <div><span className="btn btn-sm btn-danger rounded-circle">{room.unseenMsgs.length > 10 ? '10+' : room.unseenMsgs.length}</span></div> : ''}
                            </div>
                        </div>
                    ))
                }

                <div className="modal" id='modal-edit-room'>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mtz-h5">Edit room: {active ? active.name : ''}</h5>
                            </div>

                            <div className="modal-body">
                                {
                                    active &&
                                    <div>
                                        <div className="form-group">
                                            <label>Room name:</label>
                                            <input placeholder='Room name' className="form-control" defaultValue={active.name}
                                                onBlur={e => editRoom(room.id, { name: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <label>Logo:</label>
                                            <input className="form-control" type='file' onChange={e => {
                                                editRoom(room.id, { logoFile: e.target.files[0] });
                                                e.target.value = '';
                                            }} />
                                        </div>

                                        <div className="form-group">
                                            <label>Edit members:</label>

                                            <div className="mb-2 d-flex mtz-gap-8 align-items-center">
                                                <b className="fa fa-users"></b>
                                                <b className="text-danger">me</b>
                                                {
                                                    active.members && active.members.filter(m => m.id !== me.userId).map(m => (
                                                        <div key={m.id} className="align-items-center border px-2 mtz-rounded-16">
                                                            {m.firstname + " " + m.lastname} <button onClick={async () => {
                                                                const ok = await window.createMtzConfirm(`Are you sure?`);
                                                                if (!ok) return;
                                                                editRoom(room.id, { _removeMemberById: m.id });
                                                            }}
                                                                className="btn btn-sm rounded-circle"><span className="text-danger fa fa-trash"></span></button>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <input placeholder="Search user" className="form-control" value={userK}
                                                onChange={e => {
                                                    searchUser(e.target.value);
                                                    setUserK(e.target.value);
                                                }} />
                                            {
                                                users && users.length > 0 &&
                                                <div className="list-group rounded-0 position-absolute vh-50 w-100 m-1 overflow-auto">
                                                    {
                                                        users.map(user => (
                                                            <div key={user.id} className="list-group-item w-100 rounded-0">
                                                                <a href='#' onClick={async () => {
                                                                    const ok = await window.createMtzConfirm(`Do you want to invite user ${user.firstname} ${user.lastname}?`);
                                                                    if (!ok) return;

                                                                    setUsers([]);
                                                                    setUserK('');
                                                                    let invite = await chatService.createRoomInvite({ inviteeId: user.id, roomId: active.id });
                                                                    if (invite)
                                                                        alert('Invite sent');
                                                                }}>
                                                                    {`${user.firstname} ${user.lastname}`}
                                                                </a>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id='modal-view-room'>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mtz-h5">Room info: {active ? active.name : ''}</h5>
                            </div>

                            <div className="modal-body">
                                {
                                    active &&
                                    <div className="form-group">
                                        <label>Room members:</label>

                                        <div className="mb-2 d-flex mtz-gap-8 align-items-center">
                                            <b className="fa fa-users"></b>
                                            <b className="text-danger">me</b>
                                            {
                                                active.members && active.members.filter(m => m.id !== me.userId).map(m => (
                                                    <div key={m.id} className="align-items-center border px-2 mtz-rounded-16">
                                                        {m.firstname + " " + m.lastname}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const stateToProps = (state) => ({
    newRoomEvent: state.room_topic.new_uievent,
    newPresenceEvent: state.presence_topic.new_uievent,
    newMessageEvent: state.message_topic.new_uievent,
});

const dispatchToProps = (dispatch) => ({
});

const RoomsWrapper = connect(stateToProps, dispatchToProps)(withRouter(RegularRooms));

export default RoomsWrapper;