import React from "react";

import BannerImageForm from "../../../shared2/BannerImageForm";

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

export default function CompanyGroupBannerManager({
  onUpload,
  companyGroupId,
  profileName,
  width,
}) {
  let [company, setCompany] = React.useState(null);
  let [companyGroup, setCompanyGroup] = React.useState(null);
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [w, setW] = React.useState(width);
  const bannerRef = React.useRef();

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let isMounted = true;

    (async () => {
      let companyGroup;
      if (companyGroupId) {
        const companyGroups = await companyService.getCompanyGroups(
          `companyGroupIds=${companyGroupId}`
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(`profileName=${profileName}`);
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) return;

      if (isMounted) setCompanyGroup(companyGroup);

      let company = await companyService.getMyCompany();
      if (company) {
        let companyGroupMembers = await companyService.getCompanyGroupMembers(
          new URLSearchParams(`companyGroupIds=${companyGroup.id}&userIds=${me.userId}`)
        );
        if (companyGroupMembers && companyGroupMembers.length > 0) {
          if (isMounted) setCompanyGroupMember(companyGroupMembers[0]);
        }
        if (isMounted) setCompany(company);
      }

      if (bannerRef && bannerRef.current) {
        if (!width) {
          if (isMounted) setW(bannerRef.current.offsetWidth);
        } else
          if (isMounted) setW(width);
      }
    })();

    return () => { isMounted = false; };
  }, [companyGroupId, profileName, width]);

  const uploadBanner = async (fileInfo) => {
    mtzApis.startSpinningIcon();
    const data = new FormData();
    data.append("bannerFile", new File([fileInfo.blob], "bannerFile." + fileInfo.ext));
    companyGroup = await companyService.updateCompanyGroupBannerById(companyGroup.id, data);
    mtzApis.stopSpinningIcon();
    if (companyGroup) {
      setCompanyGroup(companyGroup);
      if (onUpload) onUpload(companyGroup.bannerUrl);
    }
  };

  return (
    <React.Fragment>
      {companyGroup && (
        <div id="mtz-company-group-banner" className="w-100 border mtz-rounded-16 bg-light" ref={bannerRef}>
          {companyGroup && w && (
            <BannerImageForm
              height={w / 4}
              width={w}
              editable={
                company &&
                companyGroupMember &&
                companyGroupMember.role === "ADMIN"
              }
              imageUrl={companyGroup.bannerUrl ?
                companyService.getBackendHost() + companyGroup.bannerUrl :
                '/assets/images/empty.png'}
              onUpload={uploadBanner}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
