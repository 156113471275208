import commonUtil from "../common";
import {
    PAYMENT_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzCoupons = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-coupons?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtzCouponStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-coupons/stats?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const createMtzCoupon = params => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-coupons`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}

const deleteMtzCouponById = id => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-coupons/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtCouponById = id => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-coupons/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

export {
    createMtzCoupon, deleteMtzCouponById, getMtCouponById, getMtzCoupons, getMtzCouponStats
};

