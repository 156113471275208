import React from "react";
import FooterHori from "../ParentLayout/FooterHori";

class WithTwoColumnLayout extends React.Component {

    render() {
        return (
            <div className="d-flex mtz-gap-16 justify-content-center mtz-mt-16">
                <div className="" style={{ width: '1200px' }}>
                    <div className="d-flex mtz-gap-16">
                        <div className="d-none d-xl-flex flex-column mtz-gap-16 mtz-rounded-16"
                            style={{ width: '450px' }}>
                            <div className="w-100 h-100 overflow-auto">
                                {this.props.left && <this.props.left />}
                            </div>

                            <div className="flex-grow-1"></div>

                            <div className="vh-100 overflow-auto">
                                <FooterHori />
                            </div>
                        </div>

                        <div className="flex-fill mtz-rounded-16 mb-5">
                            {this.props.center && <this.props.center />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default WithTwoColumnLayout;