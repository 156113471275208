import React from "react";

import Moment from "react-moment";
import AuthContext from "../../../AuthContext";
import mtzApis from "../../../services";

const { companyService, paymentService } = mtzApis;

function MyCouponPage() {
    let [coupons, setCoupons] = React.useState(null);
    let [couponStats, setCouponStats] = React.useState(null);
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            mtzApis.startSpinningIcon();
            const fetchedCoupons = await paymentService.getMtzCoupons(new URLSearchParams(`sellerUserIds=${me.userId}`));
            setCoupons(fetchedCoupons);

            const fetchedCouponStats = await paymentService.getMtzCouponStats(new URLSearchParams(`sellerUserIds=${me.userId}`));
            setCouponStats(fetchedCouponStats);
        } finally {
            mtzApis.stopSpinningIcon();
        }
    };

    const createCoupon = (coupon) => {
        coupons = [...coupons, coupon];
        setCoupons(coupons);
    };

    const deleteCoupon = async (coupon) => {
        let ok = await window.createMtzConfirm('Are you sure?');
        if (!ok)
            return

        try {
            mtzApis.startSpinningIcon();
            let deleted = await paymentService.deleteMtzCouponById(coupon.id);

            if (deleted) {
                alert('Coupon deleted');
                let updated = coupons.filter(c => c.id !== coupon.id);
                setCoupons(updated);
            }
        } finally {
            mtzApis.stopSpinningIcon();
        }
    };

    if (coupons === null || couponStats === null)
        return <div>Loading...</div>;

    return (
        <div className="mtz-card">
            <CreateCoupon onCreate={createCoupon} />

            <h5 className="mtz-h5">My Coupons: (found: {couponStats.all && couponStats.all.count} )</h5>

            {
                coupons && coupons.length > 0 && coupons.map(coupon => (
                    <div className="d-flex flex-column mtz-gap-8 border mtz-rounded-16 mtz-p-16">
                        <div>
                            <b>Code:</b> <span className="mtz-h5 text-primary">{coupon.code}</span>
                        </div>

                        <div>
                            <b>Value:</b> {coupon.value}
                        </div>

                        <div>
                            <b>Description:</b> {coupon.description}
                        </div>

                        <div>
                            <b>Type:</b> {coupon.type}
                        </div>

                        <div>
                            <b>Quantity:</b> {coupon.quantity}
                        </div>

                        <div>
                            <b>Expired At:</b> <Moment fromNow>{new Date(coupon.expiredAt)}</Moment>
                        </div>

                        <div>
                            <b>Created At:</b> <Moment fromNow>{new Date(coupon.createdAt)}</Moment>
                        </div>

                        <div className="text-right">
                            <button onClick={() => deleteCoupon(coupon)} className="btn btn-danger">delete</button>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

function CreateCoupon({ onCreate }) {
    const getDefaultCoupon = () => {
        let expiredDate = getDefaultExpiredDate();
        return {
            type: "PERCENTAGE",
            quantity: -1,
            expiredDate,
            expiredAt: getExpiredDateToMillis(expiredDate)
        }
    }

    const getDefaultExpiredDate = () => {
        let d = new Date();
        d.setDate(d.getDate() + 1);
        let year = d.getFullYear();
        let month = (d.getMonth() + 1 + "").length > 1 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
        let day = (d.getDate() + "").length > 1 ? d.getDate() : "0" + d.getDate();
        return year + '-' + month + '-' + day;
    }

    const getExpiredDateToMillis = (date) => {
        if (date) {
            let [year, month, day] = date.split('-');
            date = new Date(year, month - 1, day);
            date.setHours(0, 0, 0, 0);
            return date.getTime();
        }

        return 0;
    }

    const modalAddCouponRef = React.useRef();
    let [newCoupon, setNewCoupon] = React.useState(getDefaultCoupon);

    const createCoupon = async () => {
        try {
            newCoupon.expiredAt = getExpiredDateToMillis(newCoupon.expiredDate);
            let creating = { ...newCoupon };
            delete creating.expiredDate;

            mtzApis.startSpinningIcon();
            const response = await paymentService.createMtzCoupon(creating);
            if (response) {
                alert('Coupon created');
                if (onCreate)
                    onCreate(response);
                setNewCoupon(getDefaultCoupon());
                modalAddCouponRef.current.click();
            }
        } finally {
            mtzApis.stopSpinningIcon();
        }
    }

    return (
        <>
            <div>
                <button ref={modalAddCouponRef} className="btn btn-primary" data-target='#modal-add-coupon' data-toggle='modal'>
                    Create coupon <i className="fa fa-plus"></i>
                </button>
            </div>

            <div className="modal" id='modal-add-coupon'>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Coupon</h5>
                            <button onClick={() => modalAddCouponRef.current.click()} type="button" className="btn btn-close">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="code">Code: (letters and numbers only)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="6-12 characters, only letters and numbers"
                                        value={newCoupon.code || ''}
                                        onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type">Type:</label>
                                    <select onChange={(e) => setNewCoupon({ ...newCoupon, type: e.target.value })}
                                        className="form-control"
                                        value={newCoupon.type || ''}
                                    >
                                        <option value="PERCENTAGE">Percentage</option>
                                    </select>
                                </div>

                                {
                                    newCoupon.type === 'PERCENTAGE' &&
                                    <div className="form-group">
                                        <label htmlFor="value">Value in %, 0-100:</label>
                                        <input onChange={(e) => setNewCoupon({ ...newCoupon, value: parseFloat(e.target.value) || 0 })}
                                            type="number"
                                            className="form-control"
                                            value={newCoupon.value || ''}
                                            step="1"
                                            min={0} max={100}
                                            placeholder="e.g. 15"
                                        />
                                    </div>
                                }

                                <div className="form-group">
                                    <label htmlFor="quantity">Quantity:</label>
                                    <input onChange={(e) => setNewCoupon({ ...newCoupon, quantity: parseInt(e.target.value) || 0 })}
                                        type="number"
                                        className="form-control"
                                        value={newCoupon.quantity || ''}
                                    />
                                    <small className="text-danger">* -1 means unlimited number of coupons, and 1 coupon per user</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description:</label>
                                    <textarea onChange={(e) => setNewCoupon({ ...newCoupon, description: e.target.value })}
                                        type="text"
                                        className="form-control"
                                        placeholder="Coupon description"
                                        value={newCoupon.description || ''}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expiredAt">Date expired:</label> {newCoupon.expiredAt && <Moment fromNow>{new Date(newCoupon.expiredAt)}</Moment>}
                                    <input onChange={(e) => setNewCoupon({ ...newCoupon, expiredDate: e.target.value, expiredAt: getExpiredDateToMillis(e.target.value) })}
                                        type="date"
                                        className="form-control"
                                        min={
                                            (() => {
                                                let d = new Date();
                                                d.setDate(d.getDate() + 1);
                                                let year = d.getFullYear();
                                                let month = (d.getMonth() + 1 + "").length > 1 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
                                                let day = (d.getDate() + "").length > 1 ? d.getDate() : "0" + d.getDate();
                                                return year + '-' + month + '-' + day;
                                            })()
                                        }
                                        value={newCoupon.expiredDate || ''}
                                    />
                                </div>

                                <div className="text-right">
                                    <button onClick={createCoupon} type="button" className="btn btn-primary">
                                        Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCouponPage;