import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getCompanyGroupPosts = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getCompanyGroupPostStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyGroupPostById = (companyGroupPostId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createCompanyGroupPost = (companyGroupPost) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts`;
  let headers = { };
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};

export const deleteCompanyGroupPostById = (companyGroupPostId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};


export const updateCompanyGroupPostById = (companyGroupPostId, companyGroupPost) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupPost, headers);
};

export const approveCompanyGroupPostById = (companyGroupPostId) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-group-posts/${companyGroupPostId}/approve`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};
