import React from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import ResourceChartLink from "../../../shared2/ResourceChartLink";
import ShareLinkButton from '../../../shared2/ShareLinkButton';
import CompanyGroupBannerManager from "../../companyGroups2/CompanyGroupBannerManager";
import CGJoinButton from "../CGJoinButton";
import CGSponsorSubscriptionButton from "../CGSponsorSubscriptionButton";
import CompanyGroupBasicInfoViewEdit from "../CompanyGroupBasicInfoViewEdit";
import CompanyGroupChatRoomCreateLink from "../CompanyGroupChatRoomCreateLink";
import CompanyGroupDetailNavbar from "../CompanyGroupDetailNavbar";
import CompanyGroupPostManageLink from "../CompanyGroupPostManageLink";

const { metricService, companyService } = mtzApis;

function CompanyGroupDetail({ profileName, companyGroupId }) {
  const bannerLogoRef = React.useRef();
  const avatar = React.useRef();

  let [currCompanyGroup, setCurrCompanyGroup] = React.useState(null);
  let [bannerLogoWidth, setBannerLogoWidth] = React.useState();
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [sponsorMessage, setSponsorMessage] = React.useState();
  let closeFormToggle = React.useRef();



  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      window.addEventListener("resize", getBannerLogoWidth);

      let currCompanyGroup;
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`profileName=${profileName}`)
        );
        currCompanyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroupId) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`companyGroupIds=${companyGroupId}`)
        );
        currCompanyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (currCompanyGroup === null) {
        return;
      }

      let companyGroupMembers = await companyService.getCompanyGroupMembers(
        new URLSearchParams(`companyGroupIds=${currCompanyGroup.id}&userIds=${me.userId}`)
      );
      if (companyGroupMembers && companyGroupMembers.length > 0)
        setCompanyGroupMember(companyGroupMembers[0]);

      // load categories
      let cats = await companyService.getCompanyGroupCategories(new URLSearchParams(`companyGroupIds=${currCompanyGroup.id}`));
      currCompanyGroup.categories = cats || [];

      setCurrCompanyGroup(currCompanyGroup);
      getBannerLogoWidth();
    })();
    return () => {
      window.removeEventListener("resize", getBannerLogoWidth);
    };
  }, [profileName, companyGroupId]);

  const editSponsorMessage = async e => {
    e.preventDefault()
    let editParams = {
      meta: {
        ...currCompanyGroup.meta,
        sponsorMessage: sponsorMessage
      }
    };
    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyGroupById(currCompanyGroup.id, editParams);
    mtzApis.stopSpinningIcon();
    closeFormToggle.current.click()
    if (updated) {
      alert("Sponsor page message updated successfully")
      setSponsorMessage("");
    }

  }

  const getBannerLogoWidth = () => {
    if (!bannerLogoRef || !bannerLogoRef.current) return;

    let w = bannerLogoRef.current.offsetWidth;
    setBannerLogoWidth(w);
    avatar.current.style.display = "inline-block";
    avatar.current.style.position = "relative";
    avatar.current.style.top = `-${w / 7}px`;
    avatar.current.style.left = `${w / 16}px`;
    bannerLogoRef.current.style.marginBottom = `-${w / 7}px`;
  };

  const getChartData = async (queries, groupBy) => {
    let params = new URLSearchParams();
    for (let key in queries) params.append(key, queries[key]);
    params.append("groupBy", groupBy);
    params.append("companyGroupIds", currCompanyGroup.id);

    let data = await metricService.getCompanyGroupViewStatStatsUserData(params);
    return data;
  };

  const updateCGMember = async changes => {
    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyGroupMemberById(companyGroupMember.id, changes);
    mtzApis.stopSpinningIcon();

    if (updated) {
      console.log(updated)
      setCompanyGroupMember(updated);
    }
  };

  const renderIconMenu = () => {
    return (
      <div className="d-flex flex-wrap mtz-gap-4 align-items-center mtz-rounded-16">
        <CGJoinButton profileName={profileName} />
        <CGSponsorSubscriptionButton profileName={profileName} />
        <CompanyGroupChatRoomCreateLink profileName={profileName} />
        <ShareLinkButton link={window.location.href} />

        <span className="flex-fill"></span>

        {
          companyGroupMember && companyGroupMember.role === 'ADMIN' &&
          <div className="text-right">
            <button className="btn btn-outline-primary" id='admin-menu' data-toggle='dropdown'>
              <span className="fa fa-bars"></span> Admin menu
            </button>
            <div className="dropdown-menu" aria-labelledby='admin-menu'>
              <span className="dropdown-item">
                <CompanyGroupPostManageLink profileName={profileName} />
              </span>
              {
                companyGroupMember && companyGroupMember.role === "ADMIN" && (
                  <span className="dropdown-item">
                    <ResourceChartLink
                      getData={(queries, groupBy) => getChartData(queries, groupBy)}
                      title={`${currCompanyGroup.name} Visitor stats`}
                    />
                  </span>
                )
              }

              {
                currCompanyGroup &&
                <div className="dropdown-item">
                  <NavLink to={`/groups/${currCompanyGroup.profileName}/categories`} >Group Categories</NavLink>
                </div>
              }
              {
                currCompanyGroup &&
                <div className="dropdown-item">
                  <NavLink to={`/groups/${currCompanyGroup.profileName}/stats`} >View Stats</NavLink>
                </div>
              }
              {

                currCompanyGroup &&


                <div className="dropdown-item text-primary" data-toggle="modal" data-target="#exampleModal">
                  Edit Sponsor page Text
                </div>



              }
            </div>

            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">New message</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-left">
                    <form onSubmit={editSponsorMessage}>
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label"> Message:</label>
                        <input onChange={e => setSponsorMessage(e.target.value)} defaultValue={currCompanyGroup?.meta?.sponsorMessage || ""} type="text" className="form-control" id="recipient-name" />
                      </div>
                      <div className="modal-footer">
                        <button ref={closeFormToggle} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Edit Message</button>
                      </div>
                    </form>
                  </div>


                </div>
              </div>
            </div>

            {
              currCompanyGroup &&
              <div className="modal" id='group-cat-modal'>
                <div className="modal-lg modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header d-flex">
                      <h5 className="mtz-h5 flex-fill"></h5>
                      <button data-dismiss='modal' className="btn btn-sm rounded-circle"><span className="fa fa-close"></span></button>
                    </div>
                    <div className="modal-body text-left">
                      <CompanyGroupCategoryManager group={currCompanyGroup} />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }

        {
          companyGroupMember && companyGroupMember.role !== 'ADMIN' &&
          <>
            <button data-target='#member-settings-modal' data-toggle='modal' className="btn btn-outline-primary">
              <span className="fa fa-cog"></span> member settings
            </button>

            <div id='member-settings-modal' className="modal">
              <div className="modal-md modal-dialog">
                <div className="modal-content">
                  <div className="modal-header d-flex">
                    <h5 className="mtz-h5 flex-fill">My member settings on this group:</h5>
                    <button className="btn btn-sm rounded-circle" data-dismiss='modal'><span className="fa fa-close"></span></button>
                  </div>
                  <div className="modal-body">
                    {
                      currCompanyGroup && currCompanyGroup.asPrivate && currCompanyGroup.asMemberSelfHideAllowed &&
                      <div className="d-flex flex-column mtz-gap-16">
                        <div className="form-check">
                          <input checked={companyGroupMember.hidden === true} onChange={e => updateCGMember({ hidden: !companyGroupMember.hidden })}
                            type="checkbox" className="form-check-input" />
                          <label>Hide myself from others in this group</label>
                        </div>
                      </div>
                    }
                    {
                      currCompanyGroup.categories && currCompanyGroup.categories.length > 0 &&
                      <div className="form-group">
                        <label>What group category do you want classify yourself?</label>
                        <div className="dropdown">
                          <button className="btn btn-outline-secondary" data-toggle='dropdown'>-- Select group categories --</button>

                          <div className="dropdown-menu">
                            {
                              currCompanyGroup.categories.map(cat => (
                                <div className="dropdown-item" key={cat.id}>
                                  <div className="form-check">
                                    <input className="form-check-input" type='checkbox'
                                      checked={companyGroupMember?.groupCategoryIds?.includes(cat.id) || false}
                                      onChange={e => updateCGMember({
                                        groupCategoryIds: (() => {
                                          if (e.target.checked)
                                            if (!!companyGroupMember.groupCategoryIds)
                                              return [cat.id, ...companyGroupMember.groupCategoryIds];
                                            else
                                              return [cat.id];
                                          else
                                            if (!!companyGroupMember.groupCategoryIds)
                                              return companyGroupMember.groupCategoryIds.filter(id => id !== cat.id);
                                        })()
                                      })} />
                                    <label className="form-check-label">{cat.name}</label>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    );
  };

  return (
    <div className="mtz-card">
      {
        !currCompanyGroup ?
          <div className="mtz-h3">Invalid group!</div> :
          <>
            <div className="w-100">
              <CompanyGroupBannerManager
                width={bannerLogoWidth}
                profileName={profileName}
                companyGroupId={companyGroupId}
              />
            </div>

            <div className="d-flex justify-content-end">
              {renderIconMenu()}
            </div>

            <CompanyGroupBasicInfoViewEdit
              profileName={profileName}
              companyGroupId={companyGroupId}
            />

            <div>
              <CompanyGroupDetailNavbar />
            </div>
          </>
      }
    </div>
  );
}

function CompanyGroupCategoryManager({ group }) {
  let [cats, setCats] = React.useState(null);
  let [newCat, setNewCat] = React.useState({});
  let [active, setActive] = React.useState(null);
  let [changes, setChanges] = React.useState({});
  const editCatRef = React.useRef();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    cats = await companyService.getCompanyGroupCategories(new URLSearchParams(`companyGroupIds=${group.id}`));
    setCats(cats || []);
  };

  const addCat = async e => {
    e.preventDefault();

    newCat.companyGroupId = group.id;
    mtzApis.startSpinningIcon();
    let created = await companyService.createCompanyGroupCategory(newCat);
    mtzApis.stopSpinningIcon();

    if (created) {
      cats = [created, ...cats];
      setCats(cats);
      setNewCat({});
    }
  };

  const delCat = async cat => {
    const ok = await window.createMtzConfirm('Are you sure?');
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCompanyGroupCategoryById(cat.id);
    mtzApis.stopSpinningIcon();

    if (del) {
      cats = cats.filter(cat => cat.id !== del.id);
      setCats(cats);
    }
  };

  const editCat = async e => {
    e.preventDefault();

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyGroupCategoryById(active.id, changes);
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Category updated');
      cats = cats.map(cat => cat.id === updated.id ? updated : cat);
      setCats(cats);
      setChanges({});
      editCatRef.current.click();
    }
  };

  return (
    <div className="d-flex flex-column mtz-gap-16">
      <h5>Categories of the group '{group.name}':</h5>

      <div>
        <button data-toggle='collapse' data-target='#add-cat-collapse' className="btn btn-primary">
          Add <span className="fa fa-plus"></span>
        </button>
      </div>

      <div className="collapse" id='add-cat-collapse'>
        <form onSubmit={addCat}>
          <div className="form-group">
            <label>Name:</label>
            <input className="form-control" value={newCat.name}
              onChange={e => setNewCat({ ...newCat, name: e.target.value })} />
          </div>

          <div className="form-group">
            <label>Description:</label>
            <input className="form-control" value={newCat.description}
              onChange={e => setNewCat({ ...newCat, description: e.target.value })} />
          </div>

          <div className="text-right">
            <button className="btn btn-primary">Create</button>
          </div>
        </form>
      </div>

      <div>
        {cats === null && 'Loading...'}

        {cats && cats.length === 0 && 'No category found'}

        {
          cats && cats.length > 0 &&
          <div className="list-group">
            {
              cats.map(cat => (
                <div key={cat.id} className="list-group-item">
                  <div><b>Name:</b> {cat.name}</div>
                  <div><b>Description:</b> {cat.description}</div>
                  <div>
                    <button onClick={() => delCat(cat)} className="btn btn-sm btn-danger">delete</button>
                    &nbsp;
                    <button ref={editCatRef}
                      onClick={() => setActive(cat)} data-toggle='collapse' data-target="#edit-cat-modal"
                      className="btn btn-sm btn-primary">edit</button>
                    <div className="collapse" id='edit-cat-modal'>
                      <form onSubmit={editCat}>
                        <div className="form-group">
                          <label>Name:</label>
                          <input defaultValue={active ? active.name : ''}
                            className="form-control" onChange={e => setChanges({ ...changes, name: e.target.value })} />
                        </div>

                        <div className="form-group">
                          <label>Description:</label>
                          <input defaultValue={active ? active.description : ''}
                            className="form-control" onChange={e => setChanges({ ...changes, description: e.target.value })} />
                        </div>

                        <div className="text-right">
                          <button className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>
    </div>
  );
}

export default withRouter(CompanyGroupDetail);
