import { COMPANY_SERVER_URL } from "../config";

import {
  createResourceLimit,
  deleteResourceLimitById,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById
} from "./resourceLimitApi";


import * as companyExtraQuestionAnswerApi from './companyExtraQuestionAnswerApi';
import * as companyExtraQuestionApi from './companyExtraQuestionApi';
import * as companyExtraQuestionChoiceApi from './companyExtraQuestionChoiceApi';
import * as companyMemberApi from './companyMemberApi';
import * as companyMemberInviteApi from './companyMemberInviteApi';
import * as productApi from './productApi';
import * as productUploadEventApi from './productUploadEventApi';
import * as productWatchlistApi from './productWatchlistApi';
import * as rfiApi from './rfiApi';
import * as rfiType from './rfiRequestTypeApi';
import * as rfqApi from './rfqApi';
import * as rfqGroupApi from './rfqGroupApi';
import * as rfqImageApi from './rfqImageApi';
import * as rfqSearchApi from './rfqSearchApi';
import * as syncedMtzPlanSubscriptionApi from './syncedMtzPlanSubscriptionApi';
import * as tagAlertApi from './tagAlertApi';

import {
  createPrice,
  deletePriceById,
  deletePrices,
  getPriceById,
  getPrices,
  updatePriceById,
} from "./priceApi";

import {
  generateCompanySiteMap,
  generateProductSiteMap,
} from "./siteMapApi";

import * as companyApi from "./companyApi";
import { getCompanySearches, getCompanySearchStats } from "./companySearchApi";
import * as coProfilePercentApi from './coProfilePercentApi';
import * as productImageApi from './productImageApi';
import * as productSearchApi from "./productSearchApi";
import * as resourceSyncApi from './resourceSyncApi';
import * as syncedUserApi from './syncedUserApi';

import {
  createCategory,
  deleteCategories,
  deleteCategoryById,
  getCategories,
  getCategoryById,
  getCategoryCount,
  updateCategoryById
} from "./categoryApi";

import {
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  getCompanyWatchlistById,
  getCompanyWatchlists,
  getCompanyWatchlistStats,
  shareCompanyWatchlist,
  updateCompanyWatchlistById,
} from "./companyWatchlistApi";

import {
  createProductMenu,
  deleteProductMenuById,
  deleteProductMenus,
  getProductMenuById,
  getProductMenus,
  getProductMenuStats,
  updateProductMenuById
} from "./productMenuApi";

import { getEmployeeRangeCodes } from "./employeeRangeCodes";

import { getPhoneCode, getPhoneCodeCountries } from "./phoneCodeApi";

import {
  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNotes,
  getCompanyNoteStats,
  updateCompanyNoteById
} from "./companyNoteApi";

import {
  createCompanyFile,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFiles,
  getCompanyFileStats,
  updateCompanyFileById,
} from "./companyFile";

import {
  createNoteFile,
  deleteNoteFileById,
  getNoteFileById,
  getNoteFiles,
  getNoteFileStats,
  updateNoteFileById
} from "./noteFileApi";

import {
  createCompanyName,
  deleteCompanyNameById,
  getCompanyNameById,
  getCompanyNames,
  getCompanyNameStats,
  updateCompanyNameById
} from "./companyNameApi";

import {
  createCompanyPrivateFile,
  deleteCompanyPrivateFileById,
  getCompanyPrivateFileById,
  getCompanyPrivateFiles,
  getCompanyPrivateFileStats,
  updateCompanyPrivateFileById,
  viewCompanyPrivateFileById
} from "./companyPrivateFileApi";

import {
  acceptFileAccess,
  deleteFileAccess,
  requestFileAccess
} from "./fileAccessApi";

import * as adCampaignApi from "./adCampaignApi";
import * as adCampaignClickChargeApi from "./adCampaignClickChargeApi";
import * as adCampaignDepositApi from "./adCampaignDepositApi";
import * as bannerAdApi from "./bannerAdApi";
import * as bannerAdSearchApi from "./bannerAdSearchApi";
import * as cgSponsorPlanPriceApi from "./cgSponsorPlanPriceApi";
import * as cgSponsorPlanSubscriptionApi from "./cgSponsorPlanSubscriptionApi";
import * as companyActionApi from "./companyActionApi";
import * as companyAdKeywordApi from "./companyAdKeywordApi";
import * as companyAdKeywordSearchApi from "./companyAdKeywordSearchApi";
import * as companyMtzFileApi from "./companyMtzFileApi";
import * as companyMtzFileRequestApi from "./companyMtzFileRequestApi";
import * as companySearchReportApi from "./companySearchReportApi";
import * as productActionApi from "./productActionApi";
import * as productSearchReportApi from "./productSearchReportApi";
import * as textAdApi from "./textAdApi";
import * as textAdSearchApi from "./textAdSearchApi";

// network
import {
  generateCGEventSiteMap,
  generateCompanyGroupPostSiteMap,
  generateCompanyGroupSiteMap,
  generateFeedDetailSiteMap,
  generateIndexSiteMap,
  generateStaticPagesSiteMap
} from './siteMapApi';

import {
  createTrackingCode,
  // updateTrackingCodeById,
  deleteTrackingCodeById,
  getTrackingCodeById,
  getTrackingCodes,
  getTrackingCodeStats,
} from "./trackingCodeApi";

import {
  createTrackingCodeLog,
  getTrackingCodeLogs,
  getTrackingCodeStatStats
} from "./trackingCodeLogApi";

import {
  deleteCronEmails,
  getCronEmails,
  getCronEmailStats,
  sendCronEmails,
  viewSampleCronEmailById
} from './cronEmailApi';

import {
  createContact,
  deleteContactById,
  exportContacts,
  getContactById,
  getContacts,
  getContactStats,
  getContactUploadTemplate,
  getOutlookContacts,
  updateContactById,
  uploadContacts
} from "./contactApi";


import {
  createContactGroup,
  deleteContactGroupById,
  getContactGroupById,
  getContactGroups,
  getContactGroupStats,
  updateContactGroupById
} from "./contactGroupApi";

import {
  createFormBuilder,
  deleteFormBuilderById,
  getFormBuilderById,
  getFormBuilders,
  getFormBuilderStats,
  updateFormBuilderById
} from "./formBuilderApi";

import {
  createFormBuilderFeedback,
  deleteFormBuilderFeedbackById,
  getFormBuilderFeedbackById,
  getFormBuilderFeedbacks,
  getFormBuilderFeedbackStats
} from "./formBuilderFeedbackApi";

import {
  createFormBuilderItem,
  deleteFormBuilderItemById,
  getFormBuilderItemById,
  getFormBuilderItems,
  getFormBuilderItemStats,
  updateFormBuilderItemById
} from "./formBuilderItemApi";

import {
  createConnection,
  deleteConnectionById,
  deleteConnections,
  getConnectionById,
  getConnections,
  getConnectionStats,
  resendConnectionRequest,
  updateConnectionById
} from "./connectionApi";

import {
  createFollow,
  deleteFollowById,
  getFollows,
  getFollowStats
} from "./followApi";

import {
  createFeed,
  deleteFeedById,
  deleteFeeds,
  getFeedById,
  getFeeds,
  getFeedStats,
  updateFeedById,
} from "./feedApi";

import {
  createFeedFile,
  deleteFeedFileById,
  getFeedFileById,
  getFeedFiles,
  getFeedFileStats,
  updateFeedFileById,
} from "./feedFileApi";

import {
  getFeedHashtags,
  getFeedHashtagsStats
} from "./feedHashtagApi";

import {
  createEmailMarketingCampaign,
  getEmailMarketingCampaignById,
  getEmailMarketingCampaigns,
  getEmailMarketingCampaignStats,
  getMarketingEmailDynamicFields
} from "./emailMarketingCampaignApi";

import {
  createEmailSchema,
  deleteEmailSchemaById,
  getEmailSchemaById,
  getEmailSchemas,
  getEmailSchemaStats,
  testSchema,
  updateEmailSchemaById
} from "./emailSchemaApi";

import {
  getCompanyGroupPostCaches,
  getCompanyGroupPostCacheStats,
} from "./companyGroupPostCacheApi";

import {
  getFeedCaches,
  getFeedCachestats
} from "./feedCacheApi";

import * as cgEventTicketApi from './cgEventTicketApi';
import * as cgEventTicketOrderApi from './cgEventTicketOrderApi';
import * as cgMemberInviteApi from './cgMemberInviteApi';
import * as cgMemberPlanApi from "./cgMemberPlanApi";
import * as cgMemberPlanPriceApi from "./cgMemberPlanPriceApi";
import * as cgMemberPlanSubscriptionApi from "./cgMemberPlanSubscriptionApi";
import * as cgMemberRequestApi from './cgMemberRequestApi';
import * as cgSponsorInviteApi from './cgSponsorInviteApi';
import * as cgSponsorPlanApi from "./cgSponsorPlanApi";

import * as cgEventTicketOrderQRCodeApi from './cgEventTicketOrderQRCodeApi';
import {
  createCompanyGroup,
  deleteCompanyGroupById,
  getCompanyGroupById,
  getCompanyGroups,
  getCompanyGroupStats,
  updateCompanyGroupBannerById,
  updateCompanyGroupById,
  updateCompanyGroupLogoById
} from "./companyGroupApi";
import * as companyGroupCategoryApi from "./companyGroupCategoryApi";
import * as companyGroupMemberApi from "./companyGroupMemberApi";
import * as couponApi from './couponApi';
import * as couponUseApi from './couponUseApi';
import * as mtzPointBalanceApi from './mtzPointBalanceApi';
import * as mtzPointPromotionApi from './mtzPointPromotionApi';
import * as mtzPointRateApi from './mtzPointRateApi';
import * as mtzPointRewardApi from './mtzPointRewardApi';
import * as mtzScoreApi from './mtzScoreApi';
import * as referralTrackingCodeApi from './referralTrackingCodeApi';
import * as referralTrackingCodeRewardApi from './referralTrackingCodeRewardApi';
import * as rewardPointRedeemApi from './rewardPointRedeemApi';
import * as syncedCompanyApi from './syncedCompanyApi';
import * as syncedProductApi from './syncedProductApi';

import {
  acceptInviteCompanyGroupMember,
  acceptRequestCompanyGroupMember,
  inviteCompanyGroupMember,
  inviteCompanyGroupMemberByEmail,
  joinCompanyGroupMember
} from "./companyGroupMemberActionApi";

import {
  createCompanyGroupUserPref,
  deleteCompanyGroupUserPrefById,
  getCompanyGroupUserPrefById,
  getCompanyGroupUserPrefs,
  getCompanyGroupUserPrefStats,
  updateCompanyGroupUserPrefById,
} from "./companyGroupUserPrefApi";

import {
  approveCompanyGroupPostById,
  createCompanyGroupPost,
  deleteCompanyGroupPostById,
  getCompanyGroupPostById,
  getCompanyGroupPosts,
  getCompanyGroupPostStats,
  updateCompanyGroupPostById
} from "./companyGroupPostApi";

import {
  createCompanyGroupPostFile,
  deleteCompanyGroupPostFileById,
  getCompanyGroupPostFileById,
  getCompanyGroupPostFiles,
  getCompanyGroupPostFileStats,
  updateCompanyGroupPostFileById
} from "./companyGroupPostFileApi";

import {
  createCompanyGroupPostLike,
  deleteCompanyGroupPostLikeById,
  getCompanyGroupPostLikeById,
  getCompanyGroupPostLikes,
  getCompanyGroupPostLikeStats,
  updateCompanyGroupPostLikeById
} from "./companyGroupPostLikeApi";

import {
  createFeedComment,
  deleteFeedCommentById,
  getFeedCommentById,
  getFeedComments,
  getFeedCommentStats,
  updateFeedCommentById
} from "./feedCommentApi";

import {
  createCompanyGroupPostComment,
  deleteCompanyGroupPostCommentById,
  getCompanyGroupPostCommentById,
  getCompanyGroupPostComments,
  getCompanyGroupPostCommentStats,
  updateCompanyGroupPostCommentById
} from "./companyGroupPostCommentApi";

import {
  createFeedCommentReply,
  deleteFeedCommentReplyById,
  getFeedCommentReplies,
  getFeedCommentReplyById,
  getFeedCommentReplyStats,
  updateFeedCommentReplyById
} from "./feedCommentReplyApi";

import {
  createCompanyGroupPostCommentReply,
  deleteCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplies,
  getCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplyStats,
  updateCompanyGroupPostCommentReplyById
} from "./companyGroupPostCommentReplyApi";

import {
  createFeedLike,
  deleteFeedLikeById,
  getFeedLikeById,
  getFeedLikes,
  getFeedLikeStats,
  updateFeedLikeById
} from "./feedLikeApi";

import {
  createFeedCommentLike,
  deleteFeedCommentLikeById,
  getFeedCommentLikeById,
  getFeedCommentLikes,
  getFeedCommentLikeStats,
  updateFeedCommentLikeById
} from "./feedCommentLikeApi";

import {
  createCompanyGroupPostCommentLike,
  deleteCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikes,
  getCompanyGroupPostCommentLikeStats,
  updateCompanyGroupPostCommentLikeById
} from "./companyGroupPostCommentLikeApi";

import {
  createCompanyGroupPostCommentReplyLike,
  deleteCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikes,
  getCompanyGroupPostCommentReplyLikeStats,
  updateCompanyGroupPostCommentReplyLikeById
} from "./companyGroupPostCommentReplyLikeApi";

import * as cgAdBannerApi from "./cgAdBannerApi";
import * as cgAdBannerAuctionApi from "./cgAdBannerAuctionApi";
import * as cgEventApi from "./cgEventApi";
import * as cgEventInterestApi from "./cgEventInterestApi";
import * as cgEventResourceApi from "./cgEventResourceApi";
import * as cgEventSearchApi from "./cgEventSearchApi";
import * as cgSponsorAdApi from "./cgSponsorAdApi";
import * as cgSponsorClickApi from './cgSponsorClickApi';
import * as companyGroupActionApi from "./companyGroupActionApi";
import * as companyGroupBannerAdTemplateApi from "./companyGroupBannerAdTemplateApi";
import * as companyGroupPostActionApi from "./companyGroupPostActionApi";
import * as companyGroupPostSearchApi from "./companyGroupPostSearchApi";
import * as companyGroupPostSearchReportApi from "./companyGroupPostSearchReportApi";
import * as companyGroupResourceApi from "./companyGroupResourceApi";
import * as companyGroupSearchApi from "./companyGroupSearchApi";
import * as companyGroupSearchReportApi from "./companyGroupSearchReportApi";
import * as companyGroupSponsorAdTemplateApi from "./companyGroupSponsorAdTemplateApi";
import * as companyGroupSponsorApi from "./companyGroupSponsorApi";
import * as companyGroupSponsorshipApi from './companyGroupSponsorshipApi';
import * as companyGroupTypeApi from "./companyGroupTypeApi";
import * as companyGroupUploadEventApi from './companyGroupUploadEventApi';
import * as connectionQRApi from "./connectionQRApi";
import * as EmailSchemaLayoutApi from './EmailSchemaLayoutApi';
import * as feedActionApi from "./feedActionApi";
import * as feedSearchApi from "./feedSearchApi";
import * as feedSearchReportApi from "./feedSearchReportApi";
// network end

// user

import * as authReqApi from './authReqApi';
import * as healthCheckApi from './healthCheckApi';
import * as healthCheckLogApi from './healthCheckLogApi';
import * as mtzFileApi from './mtzFileApi';
import * as mtzFileSecretApi from './mtzFileSecretApi';
import * as mtzSharedFileApi from './mtzSharedFileApi';
import * as userApi from './userApi';
import * as userBioApi from './userBioApi';
import * as userBioBannerApi from './userBioBannerApi';
import * as userSearchApi from './userSearchApi';

import {
  createEmails,
  getEmailCount,
  getEmails
} from "./emailApi";

import * as geoApi from "./geoApi";
import * as mtzExceptionApi from "./mtzExceptionApi";
import * as mtzFaqApis from "./mtzFaqApi";
import * as mtzWalletTransactionApi from "./mtzWalletTransactionApi";
import * as sitemapApi from "./sitemapApi";
import * as socialAuthReqApi from './socialAuthReqApi';
import * as userExtraQuestionAnswerApi from './userExtraQuestionAnswerApi';
import * as userExtraQuestionApi from './userExtraQuestionApi';
import * as userExtraQuestionChoiceApi from './userExtraQuestionChoiceApi';
// user end

const getBackendHost = () => COMPANY_SERVER_URL;

export default {
  updateCompanyWatchlistById,
  getCompanyWatchlistById,
  getCompanyWatchlists,
  getCompanyWatchlistStats,
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  shareCompanyWatchlist,

  getCategories,
  // getCategoryStats,
  getCategoryCount,
  getCategoryById,
  deleteCategoryById,
  deleteCategories,
  updateCategoryById,
  createCategory,

  getPriceById,
  getPrices,
  createPrice,
  updatePriceById,
  deletePriceById,
  deletePrices,

  getCompanySearches,
  getCompanySearchStats,

  getBackendHost,

  getProductMenus,
  getProductMenuById,
  createProductMenu,
  deleteProductMenuById,
  deleteProductMenus,
  updateProductMenuById,
  getProductMenuStats,

  getEmployeeRangeCodes,

  getPhoneCode,
  getPhoneCodeCountries,

  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNotes,
  getCompanyNoteStats,
  updateCompanyNoteById,

  createCompanyFile,
  updateCompanyFileById,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFileStats,
  getCompanyFiles,

  generateProductSiteMap,
  generateCompanySiteMap,

  getNoteFiles,
  getNoteFileStats,
  getNoteFileById,
  createNoteFile,
  deleteNoteFileById,
  updateNoteFileById,

  getCompanyNameById,
  getCompanyNames,
  getCompanyNameStats,
  updateCompanyNameById,
  deleteCompanyNameById,
  createCompanyName,

  getCompanyPrivateFileById,
  getCompanyPrivateFileStats,
  deleteCompanyPrivateFileById,
  createCompanyPrivateFile,
  updateCompanyPrivateFileById,
  getCompanyPrivateFiles,
  viewCompanyPrivateFileById,

  requestFileAccess,
  acceptFileAccess,
  deleteFileAccess,

  createResourceLimit,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById,
  deleteResourceLimitById,

  ...companyExtraQuestionApi,
  ...companyExtraQuestionChoiceApi,
  ...companyExtraQuestionAnswerApi,
  ...syncedUserApi,
  ...coProfilePercentApi,
  ...productWatchlistApi,
  ...resourceSyncApi,
  ...companyAdKeywordApi,
  ...companyAdKeywordSearchApi,
  ...productUploadEventApi,
  ...companyActionApi,
  ...productActionApi,
  ...companySearchReportApi,
  ...productSearchReportApi,
  ...companyMemberApi,
  ...companyMemberInviteApi,
  ...adCampaignApi,
  ...textAdApi,
  ...textAdSearchApi,
  ...bannerAdApi,
  ...bannerAdSearchApi,
  ...adCampaignDepositApi,
  ...adCampaignClickChargeApi,
  ...companyMtzFileApi,
  ...companyMtzFileRequestApi,
  ...rfqApi,
  ...rfiApi,
  ...rfiType,
  ...rfqImageApi,
  ...rfqSearchApi,
  ...rfqGroupApi,
  ...syncedMtzPlanSubscriptionApi,
  ...productSearchApi,
  ...productImageApi,
  ...companyApi,
  ...productApi,
  ...tagAlertApi,

  // network
  getFollows,
  createFollow,
  deleteFollowById,
  getFollowStats,

  getBackendHost,

  createFeed,
  getFeedById,
  getFeeds,
  updateFeedById,
  deleteFeedById,
  deleteFeeds,
  getFeedStats,

  createConnection,
  resendConnectionRequest,
  deleteConnectionById,
  deleteConnections,
  updateConnectionById,
  getConnectionStats,
  getConnections,
  getConnectionById,

  generateStaticPagesSiteMap,
  generateIndexSiteMap,
  generateCompanyGroupPostSiteMap,
  generateCompanyGroupSiteMap,
  generateFeedDetailSiteMap,
  generateCGEventSiteMap,

  createContact,
  deleteContactById,
  getContactById,
  getContacts,
  getContactStats,
  updateContactById,
  uploadContacts,
  getOutlookContacts,
  exportContacts,
  getContactUploadTemplate,

  createResourceLimit,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById,
  deleteResourceLimitById,

  createContactGroup,
  deleteContactGroupById,
  getContactGroupById,
  getContactGroups,
  getContactGroupStats,
  updateContactGroupById,

  createFormBuilder,
  getFormBuilders,
  getFormBuilderStats,
  getFormBuilderById,
  deleteFormBuilderById,
  updateFormBuilderById,

  createFormBuilderFeedback,
  getFormBuilderFeedbackStats,
  getFormBuilderFeedbacks,
  getFormBuilderFeedbackById,
  deleteFormBuilderFeedbackById,

  createFormBuilderItem,
  getFormBuilderItemStats,
  getFormBuilderItems,
  getFormBuilderItemById,
  deleteFormBuilderItemById,
  updateFormBuilderItemById,

  getTrackingCodes,
  getTrackingCodeStats,
  getTrackingCodeById,
  createTrackingCode,
  deleteTrackingCodeById,

  createTrackingCodeLog,
  getTrackingCodeLogs,
  getTrackingCodeStatStats,

  createFeedFile,
  getFeedFileById,
  getFeedFiles,
  updateFeedFileById,
  deleteFeedFileById,
  getFeedFileStats,

  getFeedHashtags,
  getFeedHashtagsStats,
  createEmailMarketingCampaign,
  getEmailMarketingCampaigns,
  getMarketingEmailDynamicFields,
  getEmailMarketingCampaignStats,
  getEmailMarketingCampaignById,

  createEmailSchema,
  getEmailSchemas,
  getEmailSchemaStats,
  getEmailSchemaById,
  deleteEmailSchemaById,
  updateEmailSchemaById,
  testSchema,

  getCompanyGroupPostCacheStats,
  getCompanyGroupPostCaches,

  getFeedCaches,
  getFeedCachestats,
  createCompanyGroup,
  getCompanyGroupById,
  getCompanyGroupStats,
  getCompanyGroups,
  deleteCompanyGroupById,
  updateCompanyGroupById,
  updateCompanyGroupBannerById,
  updateCompanyGroupLogoById,
  ...companyGroupCategoryApi,
  ...companyGroupMemberApi,
  ...cgMemberPlanApi,
  ...cgMemberPlanPriceApi,
  ...cgMemberPlanSubscriptionApi,

  joinCompanyGroupMember,
  inviteCompanyGroupMember,
  acceptInviteCompanyGroupMember,
  acceptRequestCompanyGroupMember,
  inviteCompanyGroupMemberByEmail,

  createCompanyGroupUserPref,
  getCompanyGroupUserPrefById,
  getCompanyGroupUserPrefStats,
  getCompanyGroupUserPrefs,
  deleteCompanyGroupUserPrefById,
  updateCompanyGroupUserPrefById,

  getCompanyGroupPostById,
  getCompanyGroupPostStats,
  getCompanyGroupPosts,
  updateCompanyGroupPostById,
  createCompanyGroupPost,
  deleteCompanyGroupPostById,
  approveCompanyGroupPostById,

  getCompanyGroupPostFileById,
  getCompanyGroupPostFileStats,
  getCompanyGroupPostFiles,
  updateCompanyGroupPostFileById,
  createCompanyGroupPostFile,
  deleteCompanyGroupPostFileById,

  getCronEmails,
  getCronEmailStats,
  viewSampleCronEmailById,
  deleteCronEmails,
  sendCronEmails,

  getFeedLikeById,
  getFeedLikeStats,
  updateFeedLikeById,
  deleteFeedLikeById,
  createFeedLike,
  getFeedLikes,

  getFeedCommentById,
  getFeedCommentStats,
  deleteFeedCommentById,
  createFeedComment,
  updateFeedCommentById,
  getFeedComments,

  getFeedCommentReplyById,
  getFeedCommentReplyStats,
  deleteFeedCommentReplyById,
  createFeedCommentReply,
  updateFeedCommentReplyById,
  getFeedCommentReplies,

  getCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplyStats,
  deleteCompanyGroupPostCommentReplyById,
  createCompanyGroupPostCommentReply,
  updateCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplies,

  getCompanyGroupPostLikeById,
  getCompanyGroupPostLikeStats,
  deleteCompanyGroupPostLikeById,
  updateCompanyGroupPostLikeById,
  createCompanyGroupPostLike,
  getCompanyGroupPostLikes,

  getCompanyGroupPostCommentById,
  getCompanyGroupPostCommentStats,
  deleteCompanyGroupPostCommentById,
  updateCompanyGroupPostCommentById,
  createCompanyGroupPostComment,
  getCompanyGroupPostComments,

  getCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikeStats,
  getCompanyGroupPostCommentLikes,
  deleteCompanyGroupPostCommentLikeById,
  updateCompanyGroupPostCommentLikeById,
  createCompanyGroupPostCommentLike,

  getFeedCommentLikeById,
  getFeedCommentLikeStats,
  getFeedCommentLikes,
  deleteFeedCommentLikeById,
  updateFeedCommentLikeById,
  createFeedCommentLike,

  getCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikeStats,
  getCompanyGroupPostCommentReplyLikes,
  deleteCompanyGroupPostCommentReplyLikeById,
  updateCompanyGroupPostCommentReplyLikeById,
  createCompanyGroupPostCommentReplyLike,

  ...companyGroupSponsorApi,
  ...companyGroupResourceApi,
  ...cgEventApi,
  ...cgEventInterestApi,
  ...cgEventResourceApi,
  ...cgEventSearchApi,
  ...companyGroupSearchApi,
  ...companyGroupSponsorshipApi,
  ...EmailSchemaLayoutApi,
  ...syncedUserApi,
  ...syncedMtzPlanSubscriptionApi,
  ...companyGroupUploadEventApi,
  ...companyGroupTypeApi,
  ...syncedCompanyApi,
  ...syncedProductApi,
  ...companyGroupSponsorAdTemplateApi,
  ...connectionQRApi,
  ...feedSearchReportApi,
  ...companyGroupPostSearchReportApi,
  ...feedSearchApi,
  ...companyGroupPostSearchApi,
  ...feedActionApi,
  ...companyGroupPostActionApi,
  ...companyGroupActionApi,
  ...companyGroupSearchReportApi,
  ...companyGroupBannerAdTemplateApi,
  ...companyGroupSearchReportApi,
  ...cgAdBannerApi,
  ...cgAdBannerAuctionApi,
  ...cgSponsorAdApi,
  ...referralTrackingCodeApi,
  ...cgSponsorClickApi,
  ...referralTrackingCodeRewardApi,
  ...mtzPointPromotionApi,
  ...mtzPointRateApi,
  ...mtzPointBalanceApi,
  ...mtzPointRewardApi,
  ...mtzScoreApi,
  ...couponApi,
  ...couponUseApi,
  ...rewardPointRedeemApi,
  ...cgMemberInviteApi,
  ...cgMemberRequestApi,
  ...cgSponsorPlanApi,
  ...cgSponsorPlanPriceApi,
  ...cgSponsorPlanSubscriptionApi,
  ...cgSponsorInviteApi,
  ...cgEventTicketApi,
  ...cgEventTicketOrderApi,
  ...cgEventTicketOrderQRCodeApi,
  // network end

  // user
  ...authReqApi,
  ...userApi,
  ...userSearchApi,
  ...userBioApi,
  ...userBioBannerApi,

  createEmails,
  getEmails,
  getEmailCount,

  ...mtzFaqApis,
  ...mtzWalletTransactionApi,
  ...resourceSyncApi,
  ...geoApi,
  ...socialAuthReqApi,
  ...mtzExceptionApi,
  ...sitemapApi,
  ...healthCheckApi,
  ...healthCheckLogApi,
  ...mtzFileApi,
  ...mtzFileSecretApi,
  ...mtzSharedFileApi,
  ...userExtraQuestionApi,
  ...userExtraQuestionAnswerApi,
  ...userExtraQuestionChoiceApi
  // user end
};
